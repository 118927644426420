import React, { Component } from 'react';
import {
    Modal,
    Button,
  } from 'antd';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import client from '../api/client';
import {
    userAllAplicationColumn
} from './GridColumnMap';

class UserApplicationDialog extends Component {

    constructor(props){
        super(props);
        this.state = {
            data: [],
            columnDefs: userAllAplicationColumn,
            groupDefaultExpanded: 0,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50,
                width: 130,
                sortable: true,
                filter: true,
                resizable: true
            },
            autoGroupColumnDef: [{
                headerName: 'Application',
                field: 'application',
                cellClass: 'keyword',
                width: 150
            }],
        }
    }

    componentDidMount(){
        this._loadData();
    }

    _loadData = () => {
        client.getAllUserApplications().then(resp => {
            this.setState({
                data: resp
            })   
        }).catch(err => console.log(err));
    }

    _closeModal = () => {
      this.props.closeDialog();
    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.gridApi.sizeColumnsToFit();
    };

    _createDataGrid = () => {
        const { data } = this.state;
        const showData = _.isEmpty(data)?[]:_.orderBy(data, ['application'])
        return (
            <div className={`ag-theme-balham-dark grid-wrapper`} style={{height:'100%'}}>
                <AgGridReact
                              // properties
                    columnDefs={this.state.columnDefs}
                    defaultColDef={this.state.defaultColDef}
                    rowData={showData}
                    suppressAggFuncInHeader={true}
                    animateRows={true}
                    enableRangeSelection={true}
                    rowGroupPanelShow={this.state.rowGroupPanelShow}
                    groupDefaultExpanded={this.state.groupDefaultExpanded}
                    rowSelection="single"
                    onGridReady={this.onGridReady}
                />
             </div>
        )
    }

    render() {
        const { isOpened } = this.props.modalData;
        return (
            <Modal
            size="large"
            visible={isOpened}
            title={`Manage Role Permissions`}
            width={600}
            style={{ height: 600 }}
            footer={[
              <div style={{ textAlign: 'right' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this._closeModal();
                  }}
                >
                  Cancel
                </Button>
              </div>
            ]}
            closable={false}
          >
            <div style={{height:'500px', overflow:'auto'}}>
                {this._createDataGrid()}
            </div>
          </Modal>
        );
    }
}

export default UserApplicationDialog;